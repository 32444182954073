#footer {
  -webkit-filter: grayscale(90%);
  font-size: smaller;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

#logo {
  height: clamp(5vw, 50px, 15vw);
  -webkit-filter: drop-shadow(0 0 0px black);
}
#title1 {
  font-weight: bolder;
  font-size: xx-large;
}

#title2 {
  font-weight: bold;
  color: goldenrod;
  font-size: larger;
}

.rlinks {
  font-size: medium;
  font-weight: 450;
}

#location {
  border: 0;
  margin-top: 50px;
  width: "100%";
  height: "450";
  -webkit-filter: grayscale(80%);
}

#conlocation {
  display: inline;
}

.reviewdata {
  width: 80%;
  left: 10%;
  height: 35vh;
  overflow-y: scroll;
}

#reviews {
  height: 40vh;
}

#allreviews {
  margin-top: auto;
  text-align: center;
}

.image-text {
  position: relative;
  text-align: center;
}

.text-on-image {
  position: absolute;
  top: 30%;
  left: 33%;
  color: white;
  font-weight: bolder;
}

#find_facebook {
  height: 25vh;
  max-width: 25vh;
  aspect-ratio: 1/1;
  background: linear-gradient(rgba(21, 0, 250, 0.998), transparent);
  background-color: rgba(0, 47, 255, 0.5); /*this your primary color*/
  position: relative;
  text-align: center;
  margin-bottom: 2vh;
}

#find_instagram {
  height: 25vh;
  max-width: 25vh;
  aspect-ratio: 1/1;
  background: linear-gradient(rgba(254, 85, 0, 0.852), transparent);
  background-color: rgba(252, 0, 244, 0.703); /*this your primary color*/
  margin-bottom: 2vh;
  position: relative;
  text-align: center;
}

#find_icon {
  min-height: 20vh;
  min-width: 10vh;
  position: absolute;
  color: white;
  top: 10%;
  left: 30%;
}
