body {
  /* margin: 0; */
  min-height: 100vh;
  position: relative;
  margin: 0;
  padding-bottom: 6rem;

  font-family: -apple-system, BlinkMacSystemFont, "Benton Sans Condensed",
    "Benton Sans Condensed", sans-serif, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-link {
  font-family: "Edu SA Beginner", cursive;
}

p {
  font-size: large;
}
